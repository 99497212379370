var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":this.$route.meta.title}},[_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade"},{name:"id",rawName:"v-id",value:(_vm.showDismissibleAlert == true),expression:"showDismissibleAlert == true"}],staticClass:"mb-2",attrs:{"dismissible":"","fade":"","variant":"danger"},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.messages))])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('app-collapse',{attrs:{"accordion":"","type":_vm.collapseType}},[_c('app-collapse-item',{attrs:{"title":"Header"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Pre Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pre Title","label-for":"mc-title"}},[_c('b-form-input',{attrs:{"id":"mc-title","placeholder":"Pre Title"},model:{value:(_vm.model.header.preTitle),callback:function ($$v) {_vm.$set(_vm.model.header, "preTitle", $$v)},expression:"model.header.preTitle"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"mc-title"}},[_c('b-form-input',{attrs:{"id":"mc-title","placeholder":"Title"},model:{value:(_vm.model.header.title),callback:function ($$v) {_vm.$set(_vm.model.header, "title", $$v)},expression:"model.header.title"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Subtitle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Subtitle","label-for":"mc-subtitle"}},[_c('b-form-input',{attrs:{"id":"mc-subtitle","placeholder":"Subtitle"},model:{value:(_vm.model.header.subtitle),callback:function ($$v) {_vm.$set(_vm.model.header, "subtitle", $$v)},expression:"model.header.subtitle"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Image Header","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Image","label-for":"mc-icon"}},[_c('b-form-file',{attrs:{"placeholder":"Choose a file..."},on:{"change":_vm.storeHeaderImage},model:{value:(_vm.model.header.image),callback:function ($$v) {_vm.$set(_vm.model.header, "image", $$v)},expression:"model.header.image"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1)],1),_c('app-collapse-item',{attrs:{"title":"Process"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Process Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"mc-title"}},[_c('b-form-input',{attrs:{"id":"mc-title","placeholder":"Process Title"},model:{value:(_vm.model.process.title),callback:function ($$v) {_vm.$set(_vm.model.process, "title", $$v)},expression:"model.process.title"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('h5',{staticClass:"font-600 mb-2"},[_vm._v("Section Steps")]),_vm._l((4),function(page,idx){return _c('span',{key:idx},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":'Title ' + (idx + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"mc-title"}},[_c('b-form-input',{attrs:{"id":"mc-title","placeholder":"Title"},model:{value:(_vm.model.process.steps[idx].title),callback:function ($$v) {_vm.$set(_vm.model.process.steps[idx], "title", $$v)},expression:"model.process.steps[idx].title"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":'Step Image ' + (idx + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Image","label-for":"mc-icon"}},[_c('b-form-file',{attrs:{"placeholder":"Choose a file..."},on:{"change":function($event){return _vm.storeImageSteps($event, idx)}},model:{value:(_vm.model.process.steps[idx].logo),callback:function ($$v) {_vm.$set(_vm.model.process.steps[idx], "logo", $$v)},expression:"model.process.steps[idx].logo"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":'Description ' + (idx + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description","label-for":"mc-title"}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Description","rows":"4"},model:{value:(
                                    _vm.model.process.steps[idx].description
                                  ),callback:function ($$v) {_vm.$set(_vm.model.process.steps[idx], "description", $$v)},expression:"\n                                    model.process.steps[idx].description\n                                  "}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1)],1)})],2)],1)],1),_c('app-collapse-item',{attrs:{"title":"Sample Banner"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Sample Banner Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"mc-title"}},[_c('b-form-input',{attrs:{"id":"mc-title","placeholder":"Title"},model:{value:(_vm.model.downloadSampleBanner.title),callback:function ($$v) {_vm.$set(_vm.model.downloadSampleBanner, "title", $$v)},expression:"model.downloadSampleBanner.title"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Image Sample Banner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Image","label-for":"mc-icon"}},[_c('b-form-file',{attrs:{"placeholder":"Choose a file..."},on:{"change":_vm.storedownloadSampleBannerImage},model:{value:(_vm.model.downloadSampleBanner.image),callback:function ($$v) {_vm.$set(_vm.model.downloadSampleBanner, "image", $$v)},expression:"model.downloadSampleBanner.image"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Sample Banner Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description","label-for":"mc-title"}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Textarea","rows":"4"},model:{value:(_vm.model.downloadSampleBanner.description),callback:function ($$v) {_vm.$set(_vm.model.downloadSampleBanner, "description", $$v)},expression:"model.downloadSampleBanner.description"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1)],1),_c('app-collapse-item',{attrs:{"title":"Benefit"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Benefit Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"mc-title"}},[_c('b-form-input',{attrs:{"id":"mc-title","placeholder":"Title"},model:{value:(_vm.model.benefit.title),callback:function ($$v) {_vm.$set(_vm.model.benefit, "title", $$v)},expression:"model.benefit.title"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Benefit Subtitle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Subtitle","label-for":"mc-subtitle"}},[_c('b-form-input',{attrs:{"id":"mc-subtitle","placeholder":"Subtitle"},model:{value:(_vm.model.benefit.subtitle),callback:function ($$v) {_vm.$set(_vm.model.benefit, "subtitle", $$v)},expression:"model.benefit.subtitle"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1)],1),_c('app-collapse-item',{attrs:{"title":"Features"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h5',{staticClass:"font-600 mb-2"},[_vm._v("Section Fatures's")]),_vm._l((_vm.model.features),function(page,idx){return _c('span',{key:idx},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":'Features Title ' + (idx + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"mc-title"}},[_c('b-form-input',{attrs:{"id":"mc-title","placeholder":"Title"},model:{value:(_vm.model.features[idx].title),callback:function ($$v) {_vm.$set(_vm.model.features[idx], "title", $$v)},expression:"model.features[idx].title"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":'Features Subitle ' + (idx + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Subtitle","label-for":"mc-title"}},[_c('b-form-input',{attrs:{"id":"mc-title","placeholder":"Subtitle"},model:{value:(_vm.model.features[idx].subtitle),callback:function ($$v) {_vm.$set(_vm.model.features[idx], "subtitle", $$v)},expression:"model.features[idx].subtitle"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":'Logo ' + (idx + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Logo","label-for":"mc-icon"}},[_c('b-form-file',{attrs:{"placeholder":"Choose a file..."},on:{"change":function($event){return _vm.storeImageFeature($event, idx)}},model:{value:(_vm.model.features[idx].logo),callback:function ($$v) {_vm.$set(_vm.model.features[idx], "logo", $$v)},expression:"model.features[idx].logo"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),(_vm.model.features.length > 1)?_c('button',{staticClass:"btn btn-danger mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteFeatures(idx)}}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" Delete Row Features")]):_vm._e(),_vm._v("  "),_c('div',{staticClass:"divider my-1"},[_c('div',{staticClass:"divider-text"},[_vm._v("End of Row Features")])])],1)}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.addMoreFeatures}},[_vm._v(" Add More Features ")])],2)],1)],1),_c('app-collapse-item',{attrs:{"title":"FAQ"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Faq Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"mc-title"}},[_c('b-form-input',{attrs:{"id":"mc-title","placeholder":"Process Title"},model:{value:(_vm.model.faq.title),callback:function ($$v) {_vm.$set(_vm.model.faq, "title", $$v)},expression:"model.faq.title"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('h5',{staticClass:"font-600 mb-2"},[_vm._v("Section FAQ's")]),_vm._l((_vm.model.faq.faqs),function(page,idx){return _c('span',{key:idx},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":'Question ' + (idx + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Question","label-for":"mc-title"}},[_c('b-form-input',{attrs:{"id":"mc-title","placeholder":"Question"},model:{value:(_vm.model.faq.faqs[idx].question),callback:function ($$v) {_vm.$set(_vm.model.faq.faqs[idx], "question", $$v)},expression:"model.faq.faqs[idx].question"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":'Answer ' + (idx + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Answer","label-for":"mc-title"}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Answer","rows":"4"},model:{value:(_vm.model.faq.faqs[idx].answer),callback:function ($$v) {_vm.$set(_vm.model.faq.faqs[idx], "answer", $$v)},expression:"model.faq.faqs[idx].answer"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),(_vm.model.faq.faqs.length > 1)?_c('button',{staticClass:"btn btn-danger mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteFaq(idx)}}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" Delete Content")]):_vm._e(),_vm._v("  "),_c('div',{staticClass:"divider my-1"},[_c('div',{staticClass:"divider-text"},[_vm._v(" End of Row Question & Answer ")])])],1)}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.addMoreFaq}},[_vm._v(" Add More FAQ ")])],2)],1)],1),_c('app-collapse-item',{attrs:{"title":"Promo Banner"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Promo Banner Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"mc-title"}},[_c('b-form-input',{attrs:{"id":"mc-title","placeholder":"Title"},model:{value:(_vm.model.promoBanner.title),callback:function ($$v) {_vm.$set(_vm.model.promoBanner, "title", $$v)},expression:"model.promoBanner.title"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Image Promo Banner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Image","label-for":"mc-icon"}},[_c('b-form-file',{attrs:{"placeholder":"Choose a file..."},on:{"change":_vm.storepromoBannerImage},model:{value:(_vm.model.promoBanner.image),callback:function ($$v) {_vm.$set(_vm.model.promoBanner, "image", $$v)},expression:"model.promoBanner.image"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Promo Banner Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description","label-for":"mc-title"}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Textarea","rows":"4"},model:{value:(_vm.model.promoBanner.description),callback:function ($$v) {_vm.$set(_vm.model.promoBanner, "description", $$v)},expression:"model.promoBanner.description"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('b-card-footer',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Save ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"reset","variant":"outline-secondary","to":{ name: 'how-we' }}},[_vm._v(" Cancel ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }